import React from 'react';
import Seo from '~components/seo';

const PageContent = ({link}) => {
  return (
    <div className='page-content'>
      <Seo title={link} />
      <div>This page to show page content</div>
    </div>
  );
};

export default PageContent;
